<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="基本情報" icon="">
				<b-field label="名前"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'name') }"
					:message="errors.name">
					<b-input type="text" v-model="name" />
				</b-field>

				<!--
				<b-field label="説明"
					horizontal>
					<b-input type="textarea" v-model="detail" />
				</b-field>
				-->

				<b-field label="画像" horizontal>
					<div>
						<img class="preview" :src="imageBase" v-show="!image" :style="{ 'max-width': previewW + 'px' }">
						<image-picker class="mb-10" :previewUrl="image" :isLoading="isImageLoading" :previewW="previewW" @file-change="onImageChange" @delete="onImageDelete"></image-picker>
					</div>
				</b-field>

				<b-field horizontal class="form-buttons">
					<b-field grouped>
						<div class="control">
							<b-button @click="$router.go(-1)">戻る</b-button>
						</div>
						<div class="control">
							<b-button type="is-primary" :loading="isLoading" @click="onSubmitClick">{{ !id ? '登録' : '更新' }}</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>
		</section>
	</div>
</template>

<style lang="scss" scoped>
.preview {
	height: auto;
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'
import ImagePicker from '@/components/ImagePicker'

export default {
	name: 'BrandEdit',
	components: {
		//RadioPicker,
		CardComponent,
		TitleBar,
		ImagePicker,
	},
	data () {
		return {
			id: '',
			name: '',
			detail: '',
			image: '',
			imageBase: '',
			errors: {
				name: '',
			},
			isLoading: false,
			isImageLoading: false,
			previewW: 200,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		onImageDelete: function() {
			this.image = '';
		},
		onImageChange: function(file) {
			this.uploadImage(file);
		},
		uploadImage: async function(file, key) {
			this.isImageLoading = true;

			let data = {
				"image": file,
			};
			let ep = 'brands/image';

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.image = res.data.url;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isImageLoading = false;
			}
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.brandId ? '/' + this.$route.params.brandId : '';
			let ep = 'brands/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.name = res.data.name;
				this.image = res.data.image;
				this.imageBase = res.data.image_base;
				this.detail = res.data.detail;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isLoading = true;
			let data = {
				"id": this.id,
				"name": this.name,
				"image": this.image,
				"detail": this.detail,
			};

			this.$utils.log(data);

			try {
				let ep = 'brands';

				if (!this.id) {
					await this.$api.post(ep, data, true);
				} else {
					await this.$api.put(ep + '/' + this.id, data, true);
					this.$utils.toastSuccess('データを更新しました');
				}
				this.$router.push({ name: 'brand' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isLoading = false;
			}
		},
	}
}
</script>
