<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component class="has-table has-mobile-sort-spaced" :hasButtonSlot="true" title="一覧（ドラッグ＆ドロップで表示順序を変更できます）" icon="format-list-bulleted">
				<b-button slot="button" type="is-success is-small" @click="$router.push({ name: 'brand-new' })">新規作成</b-button>

				<b-table
					:loading="isLoading"
					:paginated="false"
					:striped="true"
					:hoverable="true"
					:data="list"
					draggable
					@dragstart="dragstart"
					@drop="drop"
					@dragover="dragover"
					@dragleave="dragleave"
					>

					<b-table-column label="ブランド名" field="name" v-slot="props">
						{{ props.row.name }}
					</b-table-column>

					<b-table-column label="メニュー数" field="menu_count" v-slot="props">
						{{ props.row.menu_count }}
					</b-table-column>

					<b-table-column label=" " v-slot="props">
						<div class="buttons is-right">
							<button class="button is-small is-warning" type="button" @click="$router.push({ name: 'brand-edit', params: { brandId: props.row.id }})">編集</button>
							<button class="button is-small is-danger" :style="{ 'visibility': deleteButtonVisibility(props.row.menu_count) }" type="button" @click="onDeleteClick(props.row.id)">削除</button>
						</div>
					</b-table-column>
				</b-table>
			</card-component>
		</section>
	</div>

</template>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'

export default {
	name: 'Brand',
	components: {
		TitleBar,
		CardComponent,
	},
	data () {
		return {
			isLoading: false,
			list: [],
			currentPage: 1,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
	},
	mounted () {
		this.init();
	},
	methods: {
		dragstart (payload) {
			this.draggingRow = payload.row
			this.draggingRowIndex = payload.index
			payload.event.dataTransfer.effectAllowed = 'copy'
		},
		dragover(payload) {
			payload.event.dataTransfer.dropEffect = 'copy'
			payload.event.target.closest('tr').classList.add('is-selected')
			payload.event.preventDefault()
		},
		dragleave(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected')
			payload.event.preventDefault()
		},
		drop(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected')
			const droppedOnRowIndex = payload.index
			//this.$buefy.toast.open(`Moved ${this.draggingRow.name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)

			if (this.draggingRowIndex === droppedOnRowIndex) return;

			this.reorder(this.draggingRowIndex, droppedOnRowIndex);
		},
		reorder: async function(from, to) {
			let tmpList = this.list;
			let target = tmpList.splice(from, 1);
			Array.prototype.splice.apply(tmpList, [to, 0].concat(target));

			this.isLoading = true;
			let data = [];
			for (let i in tmpList) {
				data.push({
					"id": tmpList[i].id,
					"ordering": Number(i) + 1,
				});
			}

			this.$utils.log(data);

			try {
				let ep = 'brands/reorder';
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('表示順を更新しました');
				this.list = [];
				this.list = tmpList;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		deleteButtonVisibility: function(menuCount) {
			return menuCount === 0 ? 'visible' : 'hidden';
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'brands';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.list = res.data.list;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onDeleteClick: async function(id) {
			this.$utils.log(id);
			if (!window.confirm(this.$consts.MSG_CONFIRM_DELETE)) return;

			this.isLoading = true;
			let ep = 'brands/' + id;
			this.$utils.log(ep);

			try {
				let res = await this.$api.delete(ep, null, true);
				this.$utils.log(res);
				
				this.list.some(function(value, index) {
					if (value.id === id) this.list.splice(index, 1);
				}, this);

				this.$utils.toastSuccess('削除しました');
				this.getData();
			} catch (error) {
				this.$utils.log(error);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
	},
}
</script>
